// import jwtDecode from "jwt-decode";
import { API_BASE_URL } from "../../apiservices/apiService";
import { APICore, getDecodedUserDataFromCookie } from "./apiCore";
// import Cookies from "js-cookie";

const api = new APICore();

// funvtion for admin user login
function login(params: { email: string; password: string, punchingid: string }) {
  // const baseUrl = "/login/";

  const baseUrl =   `${API_BASE_URL}/auth/login`

  return api.create(`${baseUrl}`, params);
}




// function for Student Login SMS

function studentLogin(params: { enrollment_no: string, email: string; password: string }) {
  // const baseUrl = "/login/";

  const baseUrl =   `${API_BASE_URL}/student/login`

  return api.create(`${baseUrl}`, params);
}

// function logout() {
//   const baseUrl = "/logout/";
//   return api.create(`${baseUrl}`, {});
// }

// const AUTH_SESSION_KEY = "bssa_dashboard_user";

// const getUserFromCookie = () => {
//   const user = sessionStorage.getItem(AUTH_SESSION_KEY);

//   return user ? (typeof user == "object" ? user : JSON.parse(user)) : null;
// };

// const getUserFromCookie = () => {


//   const cookieValue = Cookies.get("token");

//   if (cookieValue !== null && cookieValue !== undefined) {
//     const decoded: any = jwtDecode(cookieValue);
//     const newKey = "_id";
//     if (!(newKey in decoded)) {
//       decoded[newKey] = decoded.key;
//     }
//     return decoded
//       ? typeof decoded == "object"
//         ? decoded
//         : JSON.parse(decoded)
//       : null;
//   }
// };

// const AUTH_SESSION_KEY = "appSession-2h3jlk5";

const secretKey = '49720f6edc5c2840fb376e3c2b205cdf85d54e4868010f7f3edb7f59cfe073f7';


const getUserFromSession = () => {
  // const user = sessionStorage.getItem(AUTH_SESSION_KEY);


  return getDecodedUserDataFromCookie(secretKey)

  // const encryptedUser = sessionStorage.getItem(AUTH_SESSION_KEY);
  // if (encryptedUser) {
  //   const jsonString = atob(encryptedUser);
  //   return JSON.parse(jsonString);
  // }

  // return encryptedUser ? (typeof encryptedUser == "object" ? encryptedUser : JSON.parse(encryptedUser)) : null;
};

function logout() {
  // const baseUrl = `http://165.22.219.69:5002/auth/logout/${getUserFromSession()?._id}`;
  const baseUrl = `${API_BASE_URL}/auth/logout/${getUserFromSession()?._id}/${getUserFromSession()?.moniterd_ttl}`;
  if(getUserFromSession()?._id !== undefined && getUserFromSession()?._id !== null){
// console.log("logout API HIT")
    return api.create(`${baseUrl}`,{}, );
  }

  document.cookie = "_enco_data14_ga=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.biharsportsmashaal.in; secure; samesite=strict;";
  // This won't work due to HttpOnly, but just for reference
  document.cookie = "_enco_data14_ga=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=biharsportsmashaal.in; secure; samesite=strict;";


}

function signup(params: { fullname: string; email: string; password: string }) {
  const baseUrl = "/register/";
  return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { email: string }) {
  const baseUrl = "/forget-password/";
  return api.create(`${baseUrl}`, params);
}

export { login, logout, signup, forgotPassword, studentLogin };
