import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Container,
  Card,
  Table,
  Button,
  Spinner,
  Form,
  Pagination,
  InputGroup,
} from "react-bootstrap";
import PageTitle from "../../../components/PageTitle";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../redux/store";
import api from "../../../utils/ApiMethod";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { logoutUser } from "../../../redux/actions";
import { Alert } from "react-bootstrap";
import { ADMIN_LOGIN_BASE_URL } from "../../../apiservices/apiService";
import customStyle from "./custom.module.css";
import BatteryTestData from "../../dashboard/schooldashboard/schoolPlayers/BatteryTestData";
interface BatteryTestDetailsType {
  _id: string;
  id: number;
  studentId: string;
  studentName: string;
  fatherName: string;
  notice_doc: string;
  School_Name: string;
  height?: string;
  weight?: string;
  verticalJump?: string;
  thirtyMFlingStarts?: string;
  footballBallThrow5No?: string;
  shuttleRun10MX6?: string;
  standingBroadJump?: string;
  eightHundredMetersRun?: string;
  updatable?: Boolean;
}

type InputField =
  | "height"
  | "weight"
  | "verticalJump"
  | "thirtyMFlingStarts"
  | "footballBallThrow5No"
  | "shuttleRun10MX6"
  | "standingBroadJump"
  | "eightHundredMetersRun";

type InputValidityType = "blank" | "invalid" | "valid";

const BatteryTest = () => {


  const navigate = useNavigate();
  const location = useLocation();

  const getPageFromUrl = (): number => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    return page ? parseInt(page) : 1; // Default to 1 if page is null or not found
  };

  // const [page, setPage] = useState(getPageFromUrl);

  

  const [schoolProfiles, setSchoolProfiles] = useState<
    BatteryTestDetailsType[]
  >([]);
  const [inputData, setInputData] = useState<{
    [key: string]: {
      [key in InputField]: string;
    };
  }>({});
  const [isEditItem, setIsEditItem] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(getPageFromUrl);
  const [isError, setIsError] = useState<boolean>(false);
  const [isResponse, setIsResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const itemsPerPage = 4;
  const [searchQuery, setSearchQuery] = useState("");
  const [inputValidity, setInputValidity] = useState<{
    [key: string]: {
      [key in InputField]: InputValidityType;
    };
  }>({});
  const [inputErrors, setInputErrors] = useState<{
    [id: string]: { [field: string]: string };
  }>({});

  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    const savedProfiles = localStorage.getItem("schoolProfiles");
    if (savedProfiles) {
      setSchoolProfiles(JSON.parse(savedProfiles));
    }

    const savedInputData = localStorage.getItem("inputData");
    if (savedInputData) {
      setInputData(JSON.parse(savedInputData));
    }
  }, []);

  // console.log(schoolProfiles, "schoolProfiles");

  // Save data to local storage
  const saveDataToLocalStorage = (
    profiles: BatteryTestDetailsType[],
    data: any
  ) => {
    localStorage.setItem("schoolProfiles", JSON.stringify(profiles));
    localStorage.setItem("inputData", JSON.stringify(data));
  };
  const dispatch = useDispatch<AppDispatch>();

  const { user } = useSelector((state: RootState) => ({
    user: state.Auth.user,
  }));

  let profileId = user?._id ?? user?.user?._id ?? "";
  const Key_Key = user?.moniterd_ttl ?? user?.user?.moniterd_ttl ?? "";

  const fetchSchoolProfiles = async () => {
    setIsLoading(true);
    try {
      const response = await api.fetchDataAuth(
        `/getstudent/${profileId}/${user?.Udise_Code}/${Key_Key}`
      );
      // console.log("response?.data", response?.data);
      setSchoolProfiles(response?.data);
    } catch (error) {
      console.error("Error fetching notices:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      fetchSchoolProfiles();
    }
  }, [user, currentPage]);

  // const changePage = (currentPage: number) => {
  //   setCurrentPage(currentPage);
  //   // Update the URL with the new page number
  //   navigate(`?page=${currentPage}`);
  // };

  useEffect(() => {
    setCurrentPage(getPageFromUrl());
  }, [location.search]);


  const handleInputChange = (id: string, field: InputField, value: string) => {
    // const decimalRegex = /^\d{0,3}(\.\d{0,2})?$/;
    const decimalRegex = /^(?!0$)\d{1,3}(\.\d{0,2})?$/; // Up to 3 digits and 2 decimal places, excludes single "0"
    const containOnlyNumber = /^[0-9]*$/;
    // const containsUpToThreeNumbers = /^\d{0,3}$/;
    const containsUpToThreeNumbers = /^(?!0$)\d{1,3}$/; // Allows 1 to 3 digits but excludes single "0"
    // const timeRegex = /^([0-5]?[0-9]):([0-5]?[0-9]):([0-9]{3})$/;
    const timeRegex =
      /^(?!0:00:0{1,3}$)(?!00:00:000$)(?=.*[1-9])([0-5]?[0-9]):([0-5]?[0-9]):([0-9]{1,3})$/;

    let isValid: InputValidityType = "blank";
    let errorMessage = "";

    if (value === "") {
      isValid = "blank";
    } else {
      if (field === "height") {
        if (containsUpToThreeNumbers.test(value)) {
          isValid = "valid";
        } else {
          isValid = "invalid";
          errorMessage =
            "Invalid input. Please enter a valid number with up to three digits.";
        }
      } else if (field === "eightHundredMetersRun") {
        value = formatTime(value); // Auto-format the value to MM:SS:SSS
        if (timeRegex.test(value)) {
          isValid = "valid";
        } else {
          isValid = "invalid";
          errorMessage =
            "Invalid input. Please enter time in MM:SS:SSS format.";
        }
      } else {
        if (decimalRegex.test(value)) {
          isValid = "valid";
        } else {
          isValid = "invalid";
          errorMessage =
            "Invalid input. Please enter a valid number with up to three digits before the decimal point and up to two digits after the decimal point.";
        }
      }
    }

    setInputData((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        [field]: value,
      },
    }));

    setInputValidity((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        [field]: isValid,
      },
    }));

    setInputErrors((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        [field]: errorMessage,
      },
    }));
  };

  const formatTime = (value: string) => {
    const cleaned = value.replace(/\D+/g, ""); // Remove all non-numeric characters
    const match = cleaned.match(/^(\d{0,2})(\d{0,2})(\d{0,3})$/);
    if (match) {
      return [match[1], match[2], match[3]].filter(Boolean).join(":");
    }
    return value;
  };

  const handleSave = (id: string) => {
    // console.log("Saved data for ID:", id, inputData[id]);

    // Check if any of the inputs are invalid
    const invalidInputs = Object.values(inputValidity[id]).some(
      (isValid) => typeof isValid === "boolean" && isValid !== true
    );

    if (invalidInputs) {
      alert("Please ensure all inputs are valid before saving!");
      return;
    }

    // Update schoolProfiles state
    const updatedProfiles = schoolProfiles.map((profile) => {
      if (profile?.id === Number(id)) {
        return {
          ...profile,
          ...inputData[id],
        };
      }
      return profile;
    });

    setSchoolProfiles(updatedProfiles);

    // Save data to local storage
    saveDataToLocalStorage(updatedProfiles, inputData);

    // Show alert message
    alert("Data saved successfully!");
  };

  const editItem = async (id: string) => {
    setIsEditItem(id);
  };
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    // navigate(`?page=${currentPage}`);
    setShowAll(false); // Reset showAll when navigating through pages
  };

  useEffect(() =>{
    navigate(`?page=${currentPage}`);
  },[currentPage])

  // const handleShowAll = () => {
  //   setShowAll(true);
  //   setCurrentPage(1); // Reset to page 1 when showing all
  // };

  const handleShowAll = () => {
    setShowAll(!showAll); // Toggle the showAll state
    setCurrentPage(1); // Reset to page 1 when toggling
  };

  // Pagination function to get items for the current page
  // const paginate = (
  //   items: BatteryTestDetailsType[] | undefined,
  //   pageNumber: number,
  //   itemsPerPage: number
  // ) => {
  //   if (!items) {
  //     return [];
  //   }
  //   const startIndex = (pageNumber - 1) * itemsPerPage;
  //   const endIndex = startIndex + itemsPerPage;
  //   return items.slice(startIndex, endIndex);
  // };

  // Pagination function to get items for the current page
  const paginate = (
    items: BatteryTestDetailsType[] | undefined,
    pageNumber: number,
    itemsPerPage: number
  ) => {
    if (!items) {
      return [];
    }
    const startIndex = (pageNumber - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return items.slice(startIndex, endIndex);
  };

  // Ensure schoolProfiles is defined before filtering

  const filteredItems = schoolProfiles
    ? schoolProfiles.filter(
        (profile) =>
          profile?.studentName
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          profile?.studentId?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];

  // Calculate the indices for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Ensure filteredItems is defined before slicing
  // const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  // If showAll is true, display all filtered items; otherwise, paginate
  // const currentItems = showAll
  //   ? filteredItems // Show all items
  //   : paginate(filteredItems, currentPage, itemsPerPage); // Show paginated items

  // Pagination logic for current items
  const currentItems = showAll
    ? filteredItems // Show all items
    : paginate(filteredItems, currentPage, itemsPerPage); // Show paginated items

  console.log("currentItems", currentItems);

  const getInputBorderColor = (validity: InputValidityType) => {
    switch (validity) {
      case "blank":
        return "blue";
      case "valid":
        return "green";
      case "invalid":
        return "red";
      default:
        return "";
    }
  };
  // console.log("inputData", inputData);
  const submitBatteryTest = async (studentId: any, action: string) => {
    // event.preventDefault();
    const hasErrors = Object.values(inputErrors).some((errorObj) =>
      Object.values(errorObj).some((error) => error !== "")
    );

    if (hasErrors) {
      alert("Please fix the error in the form.");
      // setIsError(true);
      // setIsResponse("Please fix the errors in the form.");
      return;
    }

    const formDataToSend = new FormData();

    try {
      for (const key in inputData) {
        if (inputData.hasOwnProperty(key)) {
          const data = inputData[key];
          for (const field in data) {
            if (data.hasOwnProperty(field)) {
              // const value = data[field];
              const value = data[field as InputField];
              formDataToSend.append(`${key}[${field}]`, value);
            }
          }
        }
      }
      // Append each piece of state data to the FormData object
      // /student/register/:userId/:moniterd_ttl
      const response = await api.updatePatchData(
        `/save/student/battery-test/${profileId}/${studentId}/${action}/${Key_Key}`,
        formDataToSend
      );
      if (response.status === 440) {
        alert("Session Expired");
        dispatch(logoutUser());
        window.location.href = ADMIN_LOGIN_BASE_URL; // Use the full URL, including the protocol (http or https)
      }
      if (response.status === 400) {
        setIsError(true);
        let errorMessage = await response.message;
        setIsResponse(errorMessage);
      }
      if (!response.status == true) {
        // console.log("response--", await response.json())
        let errorMsg = await response.json();
        setIsResponse(errorMsg.message);
        // console.log("response from update user---",response)
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      if (response.status == 200 || response.status == 201) {
        alert("Battery Test Result Saved Successfully");
        window.location.reload();
      }
    } catch (error: any) {
      // setIsResponse(error)
      // setShowModal(true)
      console.error("Error during save battery test result:", error);
    }
  };

  // console.log("schoolProfiles", schoolProfiles);

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "School", path: "/dashboard/school" },
          {
            label: "Battery Test",
            path: "/pages/battery-test",
            active: true,
          },
        ]}
        title={"Battery Test"}
      />

      <Row>
        <Col>
          <BatteryTestData players={schoolProfiles} />
          <Card
            style={{
              background: "#07294d",
              color: "#ffffff",
              textAlign: "center",
            }}
          >
            <Card.Body>
              <h1 className="center" style={{ color: "#ffffff" }}>
                ENTER YOUR BATTERY TEST SCORE DETAILS
              </h1>
              {isError && (
                <Alert variant="danger" className="my-2">
                  {isResponse}
                </Alert>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <InputGroup className="mb-3" style={{ maxWidth: "500px" }}>
            <InputGroup.Text>Search</InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="Search by Player name or Player ID"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Card>
          <Card.Body>
            <Container>
              <div
                className={`${customStyle.tableSticky} ${customStyle.tableStickyContainer}`}
              >
                <Table
                  responsive
                  striped
                  bordered
                  hover
                  className={`${customStyle.tableheadSticky}`}
                >
                  <thead>
                    {/* <thead style={{ position: 'sticky', top: 0, zIndex: 1000, backgroundColor: '#fff' }}> */}
                    <tr className="text-center">
                      <th>Sr.No</th>
                      <th>Player ID</th>
                      <th>Player Name</th>
                      {/* <th>profile id</th> */}
                      <th>Father's Name</th>
                      <th>Height (in cm)</th>
                      <th>Weight (in Kg)</th>
                      <th>Vertical Jump (in m)</th>
                      <th>30 Mtr sprint(in sec.)</th>
                      <th>Football Throw (m)</th>
                      <th>6X10 Mtr shuttle run (in second)</th>
                      <th>Standing Broad jump(in cm)</th>
                      <th>800 mts Run ( in mm:ss:sss)</th>
                      <th>Save</th>
                      <th>Submit</th>
                    </tr>
                  </thead>
                  {isLoading ? (
                    <tbody>
                      <tr>
                        <td colSpan={14} className="text-center">
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </td>
                      </tr>
                    </tbody>
                  ) : currentItems?.length === 0 ? (
                    <tbody>
                      <tr>
                        <td colSpan={14} className="text-center">
                          No data found
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {currentItems?.map((profile, i) => (
                        <tr key={i}>
                          <td>{indexOfFirstItem + i + 1}</td>
                          <td> {profile?.studentId}</td>
                          <td>
                            <Link
                              to={`/pages/student-profile/${profile?._id}`}

                              // onClick={() =>
                              //   profile?._id !== undefined && editItem(profile._id)
                              // }
                            >
                              {profile.studentName}
                            </Link>
                          </td>
                          <td>{profile.fatherName}</td>
                          <td>
                            <Form.Control
                              type="text"
                              readOnly={!profile?.updatable}
                              defaultValue={
                                inputData[profile._id]?.height ||
                                profile?.height
                              }
                              autoComplete="off"
                              onChange={(e) =>
                                handleInputChange(
                                  profile._id,
                                  "height",
                                  e.target.value
                                )
                              }
                              style={{
                                borderColor: getInputBorderColor(
                                  inputValidity[profile._id]?.height
                                ),
                                width: "5rem",
                                textAlign: "center",
                              }}
                              pattern="\d{3}"
                            />
                            {inputValidity[profile._id] &&
                              inputValidity[profile._id].height ===
                                "invalid" && (
                                <div style={{ color: "red" }}>
                                  {inputErrors[profile._id] &&
                                    inputErrors[profile._id].height}
                                </div>
                              )}
                          </td>
                          <td>
                            <Form.Control
                              type="text"
                              autoComplete="off"
                              readOnly={!profile?.updatable}
                              defaultValue={
                                inputData[profile._id]?.weight ||
                                profile?.weight
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  profile._id,
                                  "weight",
                                  e.target.value
                                )
                              }
                              style={{
                                borderColor: getInputBorderColor(
                                  inputValidity[profile._id]?.weight
                                ),
                                width: "5rem",
                                textAlign: "center",
                              }}
                            />
                            {inputValidity[profile._id] &&
                              inputValidity[profile._id].weight ===
                                "invalid" && (
                                <div style={{ color: "red" }}>
                                  {inputErrors[profile._id] &&
                                    inputErrors[profile._id].weight}
                                </div>
                              )}
                          </td>
                          <td>
                            <Form.Control
                              type="text"
                              readOnly={!profile?.updatable}
                              defaultValue={
                                inputData[profile._id]?.verticalJump ||
                                profile?.verticalJump
                              }
                              autoComplete="off"
                              onChange={(e) =>
                                handleInputChange(
                                  profile._id,
                                  "verticalJump",
                                  e.target.value
                                )
                              }
                              style={{
                                borderColor: getInputBorderColor(
                                  inputValidity[profile._id]?.verticalJump
                                ),
                                width: "5rem",
                                textAlign: "center",
                              }}
                            />
                            {inputValidity[profile._id] &&
                              inputValidity[profile._id].verticalJump ===
                                "invalid" && (
                                <div style={{ color: "red" }}>
                                  {inputErrors[profile._id] &&
                                    inputErrors[profile._id].verticalJump}
                                </div>
                              )}
                          </td>
                          <td>
                            <Form.Control
                              type="text"
                              readOnly={!profile?.updatable}
                              defaultValue={
                                inputData[profile._id]?.thirtyMFlingStarts ||
                                profile?.thirtyMFlingStarts
                              }
                              autoComplete="off"
                              onChange={(e) =>
                                handleInputChange(
                                  profile._id,
                                  "thirtyMFlingStarts",
                                  e.target.value
                                )
                              }
                              style={{
                                borderColor: getInputBorderColor(
                                  inputValidity[profile?._id]
                                    ?.thirtyMFlingStarts
                                ),
                                width: "5rem",
                                textAlign: "center",
                              }}
                            />
                            {inputValidity[profile._id] &&
                              inputValidity[profile._id].thirtyMFlingStarts ===
                                "invalid" && (
                                <div style={{ color: "red" }}>
                                  {inputErrors[profile._id] &&
                                    inputErrors[profile._id].thirtyMFlingStarts}
                                </div>
                              )}
                          </td>
                          <td>
                            <Form.Control
                              type="text"
                              readOnly={!profile?.updatable}
                              required
                              autoComplete="off"
                              defaultValue={
                                inputData[profile._id]?.footballBallThrow5No ||
                                profile?.footballBallThrow5No
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  profile?._id,
                                  "footballBallThrow5No",
                                  e.target.value
                                )
                              }
                              style={{
                                borderColor: getInputBorderColor(
                                  inputValidity[profile._id]
                                    ?.footballBallThrow5No
                                ),
                                width: "5rem",
                                textAlign: "center",
                              }}
                            />
                            {inputValidity[profile._id] &&
                              inputValidity[profile._id]
                                .footballBallThrow5No === "invalid" && (
                                <div style={{ color: "red" }}>
                                  {inputErrors[profile._id] &&
                                    inputErrors[profile._id]
                                      .footballBallThrow5No}
                                </div>
                              )}
                          </td>
                          <td>
                            <Form.Control
                              type="text"
                              readOnly={!profile?.updatable}
                              defaultValue={
                                inputData[profile._id]?.shuttleRun10MX6 ||
                                profile?.shuttleRun10MX6
                              }
                              autoComplete="off"
                              onChange={(e) =>
                                handleInputChange(
                                  profile._id,
                                  "shuttleRun10MX6",
                                  e.target.value
                                )
                              }
                              style={{
                                borderColor: getInputBorderColor(
                                  inputValidity[profile._id]?.shuttleRun10MX6
                                ),
                                width: "5rem",
                                textAlign: "center",
                              }}
                            />
                            {inputValidity[profile._id] &&
                              inputValidity[profile._id].shuttleRun10MX6 ===
                                "invalid" && (
                                <div style={{ color: "red" }}>
                                  {inputErrors[profile._id] &&
                                    inputErrors[profile._id].shuttleRun10MX6}
                                </div>
                              )}
                          </td>
                          <td>
                            <Form.Control
                              type="text"
                              readOnly={!profile?.updatable}
                              defaultValue={
                                inputData[profile._id]?.standingBroadJump ||
                                profile?.standingBroadJump
                              }
                              autoComplete="off"
                              onChange={(e) =>
                                handleInputChange(
                                  profile._id,
                                  "standingBroadJump",
                                  e.target.value
                                )
                              }
                              style={{
                                borderColor: getInputBorderColor(
                                  inputValidity[profile._id]?.standingBroadJump
                                ),
                                width: "5rem",
                                textAlign: "center",
                              }}
                            />
                            {inputValidity[profile._id] &&
                              inputValidity[profile._id].standingBroadJump ===
                                "invalid" && (
                                <div style={{ color: "red" }}>
                                  {inputErrors[profile._id] &&
                                    inputErrors[profile._id].standingBroadJump}
                                </div>
                              )}
                          </td>
                          <td>
                            <Form.Control
                              type="text"
                              readOnly={!profile?.updatable}
                              value={
                                inputData[profile._id]?.eightHundredMetersRun ||
                                profile?.eightHundredMetersRun ||
                                ""
                              }
                              autoComplete="off"
                              onChange={(e) =>
                                handleInputChange(
                                  profile._id,
                                  "eightHundredMetersRun",
                                  e.target.value
                                )
                              }
                              style={{
                                borderColor: getInputBorderColor(
                                  inputValidity[profile._id]
                                    ?.eightHundredMetersRun
                                ),
                                width: "7rem", // Adjust width for the new format
                                textAlign: "center",
                              }}
                              placeholder="MM:SS:SSS" // Placeholder to guide user input
                            />
                            {inputValidity[profile._id] &&
                              inputValidity[profile._id]
                                .eightHundredMetersRun === "invalid" && (
                                <div style={{ color: "red" }}>
                                  {inputErrors[profile._id] &&
                                    inputErrors[profile._id]
                                      .eightHundredMetersRun}
                                </div>
                              )}
                          </td>

                          {/* <td>
                          <Button onClick={() => handleSave(profile._id)}>
                            Save
                          </Button>
                        </td> */}
                          {profile?.updatable === true ? (
                            <td>
                              <Button
                                onClick={() =>
                                  submitBatteryTest(profile._id, "save")
                                }
                              >
                                Save
                              </Button>
                            </td>
                          ) : (
                            <td>Saved</td>
                          )}

                          {profile?.updatable === true ? (
                            <td>
                              <Link
                                to={`/pages/student-profile/${profile?._id}`}

                                // onClick={() =>
                                //   profile?._id !== undefined && editItem(profile._id)
                                // }
                              >
                                Review and Submit
                              </Link>
                            </td>
                          ) : (
                            <td>Submitted </td>
                          )}

                          {/* <td>
                          <Button
                            onClick={() => submitBatteryTest(profile._id)}
                          >
                            Submit
                          </Button>
                        </td> */}
                        </tr>
                      ))}
                    </tbody>
                  )}
                </Table>
              </div>

              {!showAll && (
                <Pagination>
                  <Pagination.First onClick={() => handlePageChange(1)} />
                  <Pagination.Prev
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  />
                  {Array.from(
                    {
                      length: Math.ceil(
                        (schoolProfiles?.length || 0) / itemsPerPage
                      ),
                    },
                    (_, index) => index + 1
                  ).map((item) => (
                    <Pagination.Item
                      key={item}
                      active={item === currentPage}
                      onClick={() => handlePageChange(item)}
                    >
                      {item}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={
                      currentPage ===
                      Math.ceil((schoolProfiles?.length || 0) / itemsPerPage)
                    }
                  />
                  <Pagination.Last
                    onClick={() =>
                      handlePageChange(
                        Math.ceil((schoolProfiles?.length || 0) / itemsPerPage)
                      )
                    }
                  />
                </Pagination>
              )}

              <Button variant="primary" onClick={handleShowAll}>
                {showAll ? "Show Paginated" : "Show All"}
              </Button>

              {/* Show All Button */}
              {/* <button onClick={handleShowAll}>Show All</button> */}
            </Container>
          </Card.Body>
        </Card>
      </Row>
    </>
  );
};

export default BatteryTest;
