// import { height } from "@mui/system";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Row,
  Table,
  Toast,
} from "react-bootstrap";

import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import AuthModal from "../../components/Modal";
import { Alert } from "react-bootstrap";
import { logoutUser } from "../../redux/actions";
import api from "../../utils/ApiMethod";
import AproveDepartment from "../Departments/AproveDepartment";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import img from "../../assets/images/new.gif";
import PageTitle from "../../components/PageTitle";
import Alerts from "../uikit/Alerts";
import Toasts from "../uikit/Toasts";
import { ADMIN_LOGIN_BASE_URL } from "../../apiservices/apiService";

//---------------------- Interface for UG Registration ----------------------------

interface UGStudentData {
  id: string;
  course_name: string;
  form_no: string | null;
  enrollment_no: string;
  session: string;
  admission_date: string;
  roll_number: string;
  rank: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  dob: string;
  category: string;
  category_selected: string;
  gender: string;
  nationality: string;
  religion: string;
  student_mobile: string;
  email: string;
  aiims_email: string;
  identification_mark: string;
  std_bloodgrp: string;
  father_name: string;
  mother_name: string;
  student_image: string | null;
  sign_image: string | null;
  admission_letter: string | null;
  corr_stateName: string;
  corr_districtName: string;
  corr_town: string;
  corr_police_station: string;
  corr_post_office: string;
  corr_street_address: string;
  corr_pin: string;
  corr_landline: string;
  corr_mobile: string;
  corr_email: string;
  perm_stateName: string;
  perm_districtName: string;
  perm_town: string;
  perm_police_station: string;
  perm_post_office: string;
  perm_street_address: string;
  perm_pin: string;
  perm_landline: string;
  perm_mobile: string;
  perm_email: string;
  gardi_fname: string;
  gard_lname: string;
  gard_mobile: string;
  guardian_email: string;
  relation: string;
  gardian_address: string;
  school_name: string;
  school_state: string;
  school_city: string;
  school_board: string;
  pass_year: string;
  school_marks: string;
  marks_obtained: string;
  schoolMarksInPercentage: string;
  school_cgpa: string;
  school_division: string;
  schoolname_ten_plus_two: string;
  state_Name_ten_plus_two: string;
  city_ten_plus_two: string;
  board_ten_plus_two: string;
  passyear_ten_plus_two: string;
  marks_ten_plus_two: string;
  marks_ten_plus_two_obtained: string;
  tenPlusTwoMarksInPercentage: string;
  cgpa_ten_plus_two: string;
  division_ten_plus_two: string;
  MedicalRegistration: string;
  RegisrationState: string;
  PGRegistration: string;
  isDetailsUpdated: string;
  status: string;
  rejectionReason: string;
  same_addr: boolean;
  //   approvedby: string | null;
  //   update_date: string | null;
  updated_by: string;
}

//  data base connected to this model facultyOfMedicineModel

interface Child {
  _id: string;
  url?: string;
  title?: string;
  sportDetails: string;
  image: string;
  doc: string;
  isDeleted: boolean;
  address?: string;
  pin?: string;
  bgColor?: string;
  text?: string;
  content?: string;
  introduction?: string;
  researchBy?: string;
  awards?: string;
  modifiedBy?: string;
  createdBy?: string;
  researcherName?: string;
  yearOfResearch?: string;
  selectedDate?: Date;
  selectedDateTo?: Date;
  subject?: string;
  mission?: string;
  name?: string;
  vision?: string;
  summary?: string;
  date?: Date;
  dateTo?: Date;
  coreValues?: string;
  superDuperAdminApprovalStatus?: string;
  adminApprovalStatus?: string;
}

interface link {
  _id: string;
  title: string;
  url: string;
  image: string;
  doc: string;
  pin: string;
  isDeleted: boolean;
}

interface address {
  _id: string;
  title: string;
  address: string;
  pin: string;
  isDeleted: boolean;
}

interface Parent {
  itemId?: string; // Replace 'boolean' with the appropriate type if needed
  _id?: string;
  text?: string;
  content?: string;
  bannerUrl?: string;
  mobileBannerUrl?: string;
  name?: string;
  numberOfVisitors: string;
  data: [] | undefined;
  aboutus?: string; // An array of OrgtnalSruItem objects
  facultyAndStaff?: string; // An array of OrgtnalSruItem objects
  researchAndPublications?: string; // An array of OrgtnalSruItem objects
  academics?: string; // An array of OrgtnalSruItem objects
  inPatientService?: string; // An array of OrgtnalSruItem objects
  outPatientService?: string; // An array of OrgtnalSruItem objects
  priceList?: string; // An array of OrgtnalSruItem objects
  MisionAndVision?: string; // An array of OrgtnalSruItem objects
  links?: string; // An array of OrgtnalSruItem objects
  gallery?: string; // An array of OrgtnalSruItem objects
  innerData?: Child[];
  // OrgtnalSruItem:array;
  isDeleted: boolean;
  notice?: string;
  headOfDepartment?: string;
  infrastructure?: string;
  learningObjective?: string;
  bestPractices?: string;
  universityPosition?: string;
  researchAndProjects?: string;
  eventOrgDeprtmt?: string;
  studentCorner?: string;
  facultyOfResidents?: string;
  facultyOfMember?: string;
}

interface id {
  id: string;
  link?: string;
  name?: string;
  activeDepartmentName?: string;
}

interface Props {
  itemId: string; // Replace 'boolean' with the appropriate type if needed
  parentId?: string;
  innerdata: Child[];
  innerData?: Child[]; // Specify the correct type for innerData
  name?: string;
}

interface parentId {
  itemId?: string; // Replace 'boolean' with the appropriate type if needed
  id?: string;
  _id?: string | undefined;
  text?: string;
  parentId?: string | undefined;
  // data?: typeof parent[];
  data?: Parent[]; // Specify the correct prop type
  link?: string; // An array of OrgtnalSruItem objects
  address?: string;
  joinus?: string;
  inportantLink?: string;
  name?: string;
  activeDepartmentName?: string;
  innerData?: Child[];
}

const UgRegistration = () => {
  const [validated, setValidated] = useState<boolean>(false);
  const [data, setData] = useState<Parent[]>([]);
  const [text, setText] = useState("");
  const [content, setContent] = useState("");

  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [districts, setDistricts] = useState([]);

  const [link, setLink] = useState("");
  const [image, setImage] = useState<File | null>(null);
  const [innerData, setInnerData] = useState("");
  const [name, setName] = useState("");
  const [buttonLoading, setButtonLoding] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isResponse, setIsResponse] = useState("");
  const dispatch = useDispatch<AppDispatch>();
  const [showToast, setShowToast] = useState(false);

  // Initial Data for UG student

  const [ugStudentData, setUGStudentData] = useState<UGStudentData>({
    id: "",
    course_name: "",
    form_no: "",
    enrollment_no: "",
    session: "",
    admission_date: "",
    roll_number: "",
    rank: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    dob: "",
    category: "",
    category_selected: "",
    gender: "",
    nationality: "",
    religion: "",
    student_mobile: "",
    email: "",
    aiims_email: "",
    identification_mark: "",
    std_bloodgrp: "",
    father_name: "",
    mother_name: "",
    student_image: "",
    sign_image: "",
    admission_letter: "",
    corr_stateName: "",
    corr_districtName: "",
    corr_town: "",
    corr_police_station: "",
    corr_post_office: "",
    corr_street_address: "",
    corr_pin: "",
    corr_landline: "",
    corr_mobile: "",
    corr_email: "",
    perm_stateName: "",
    perm_districtName: "",
    perm_town: "",
    perm_police_station: "",
    perm_post_office: "",
    perm_street_address: "",
    perm_pin: "",
    perm_landline: "",
    perm_mobile: "",
    perm_email: "",
    gardi_fname: "",
    gard_lname: "",
    gard_mobile: "",
    guardian_email: "",
    relation: "",
    gardian_address: "",
    school_name: "",
    school_state: "",
    school_city: "",
    school_board: "",
    pass_year: "",
    school_marks: "",
    marks_obtained: "",
    schoolMarksInPercentage: "",
    school_cgpa: "",
    school_division: "",
    schoolname_ten_plus_two: "",
    state_Name_ten_plus_two: "",
    city_ten_plus_two: "",
    board_ten_plus_two: "",
    passyear_ten_plus_two: "",
    marks_ten_plus_two: "",
    marks_ten_plus_two_obtained: "",
    tenPlusTwoMarksInPercentage: "",
    cgpa_ten_plus_two: "",
    division_ten_plus_two: "",
    MedicalRegistration: "", // not used here
    RegisrationState: "", // not used here
    PGRegistration: "", // not used here
    isDetailsUpdated: "",
    status: "",
    rejectionReason: "",
    same_addr: false,
    updated_by: "",
  });

  const handleChange = (e: React.ChangeEvent<any>) => {
    const { name, value, files, type, checked } = e.target;

    // If the input type is a checkbox, update the state with the checked status
    if (type === "checkbox") {
      setUGStudentData((prevData) => ({ ...prevData, [name]: checked }));

      // Access the updated state directly instead of using prevData
      if (checked && ugStudentData.same_addr) {
        setUGStudentData((prevData) => ({
          ...prevData,
          perm_stateName: prevData.corr_stateName,
          perm_districtName: prevData.corr_districtName,
          perm_town: prevData.corr_town,
          perm_police_station: prevData.corr_police_station,
          perm_post_office: prevData.corr_post_office,
          perm_street_address: prevData.corr_street_address,
          perm_pin: prevData.corr_pin,
          perm_landline: prevData.corr_landline,
          perm_mobile: prevData.corr_mobile,
          perm_email: prevData.corr_email,
        }));
      }
    } else if (files && files.length > 0) {
      // If the input type is file, update the state with the file and its name
      setUGStudentData((prevData) => ({
        ...prevData,
        [name]: files[0],
        // image: files[0].name,
      }));
    } else if (type === "select-one") {
      // If the input type is select-one (dropdown), update the state with the selected value
      setUGStudentData((prevData) => ({ ...prevData, [name]: value }));
    } else {
      // If the input type is not a file or checkbox, update the state as usual
      setUGStudentData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  // const handleChange = (e: React.ChangeEvent<any>) => {
  //   const { name, value, files, type, checked } = e.target;

  //   if (type === "checkbox") {
  //     // If the input type is a checkbox, update the state with the checked status
  //     setUGStudentData((prevData) => ({ ...prevData, [name]: checked }));
  //   } else if (files && files.length > 0) {
  //     // If the input type is file, update the state with the file and its name
  //     setUGStudentData((prevData) => ({
  //       ...prevData,
  //       [name]: files[0],
  //       // image: files[0].name,
  //     }));
  //   } else if (type === "select-one") {
  //     // If the input type is select-one (dropdown), update the state with the selected value
  //     setUGStudentData((prevData) => ({ ...prevData, [name]: value }));
  //   } else {
  //     // If the input type is not a file or checkbox, update the state as usual
  //     setUGStudentData((prevData) => ({ ...prevData, [name]: value }));
  //   }
  // };

  // const handleChange = (e: React.ChangeEvent<any>) => {
  //   const { name, value, files, type, checked } = e.target;

  //   if (type === "checkbox") {
  //     // If the input type is a checkbox, update the state with the checked status
  //     setUGStudentData((prevData) => ({ ...prevData, [name]: checked }));
  //   } else if (files && files.length > 0) {
  //     // If the input type is file, update the state with the file and its name
  //     setUGStudentData((prevData) => ({
  //       ...prevData,
  //       [name]: files[0],
  //       // image: files[0].name,
  //     }));
  //   } else if (type === "select-one") {
  //     // If the input type is select-one (dropdown), update the state with the selected value
  //     setUGStudentData((prevData) => ({ ...prevData, [name]: value }));
  //     console.log(ugStudentData, "AFTER DISTRICT SELECT");
  //   } else {
  //     // If the input type is not a file or checkbox, update the state as usual
  //     setUGStudentData((prevData) => ({ ...prevData, [name]: value }));

  //     // Calculate percentage if both total marks and marks obtained are available
  //     if (name === "school_marks" || name === "marks_obtained") {
  //       const totalMarks = parseFloat(value);
  //       const marksObtained = parseFloat(ugStudentData.marks_obtained);
  //       if (!isNaN(totalMarks) && !isNaN(marksObtained) && totalMarks !== 0) {
  //         const percentage = (marksObtained / totalMarks) * 100;
  //         setUGStudentData((prevData) => ({
  //           ...prevData,
  //           schoolMarksInPercentage: percentage.toFixed(2),
  //         }));
  //       }
  //     }
  //   }
  // };

  const { user, userLoggedIn, loading, error, userId } = useSelector(
    (state: RootState) => ({
      user: state.Auth.user,
      loading: state.Auth.loading,
      error: state.Auth.error,
      userLoggedIn: state.Auth.userLoggedIn,
      userId: state.Auth._id,
    })
  );
  let profileId = user._id ? user._id : user.user ? user.user._id : "";
  let Role = user.hobbies ? user.hobbies : user.user.hobbies;
  let profileName = user.name ? user.name : user.user.name;
  let subDepartmentName = user.department
    ? user.department
    : user.user.department;

  const Key_Key = user.moniterd_ttl
    ? user.moniterd_ttl
    : user.user
    ? user.user.moniterd_ttl
    : "";

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await fetch(
        //   "http://165.22.219.69:5002/api/footerpage"
        // );
        const departmentName = "FOM";
        let innerUrl = "";
        if (Role === "Chess") {
          innerUrl = `/api/department/${departmentName}`;
        } else if (Role == "Music") {
          innerUrl = `/api/department/${subDepartmentName}/${departmentName}`;
        } else {
          innerUrl = `notforyou`;
        }
        const response = await api.fetchData(innerUrl);
        // const response = await fetch("http://165.22.219.69:5002/api/footerpage");

        if (!response.status == true) {
          // console.log("response--", await response.json())
          let errorMsg = await response.json();
          setIsResponse(errorMsg.message);
          // console.log("response from update user---",response)
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.data;

        if (data.length > 0) {
          // Assuming you want to set the pageId to the first item's _id
          // setPageId(data[0]._id);
        }

        setData(data);
        setLink(data[0]?.link);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the async function to fetch and set data
  }, []);

  const handleSubmit = async (event: any) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  //   ------------------------------------------ setting image in the input--------------------------------
  const handleBannerUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImage(e.target.files[0]);
    }
  };

  /*---------------- handle Form submit ---------------------------------------*/

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      setButtonLoding(true);
      const formData = new FormData();

      // Append each field from ugStudentData to the formData
      Object.entries(ugStudentData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      // Append additional fields if needed
      formData.append("department", "FOM");
      formData.append("createdBy", profileName);

      // console.log(formData, "FORMDATA FOR SUBMIT");

      try {
        const response = await api.createData(
          `/register/${profileId}/${Key_Key}`,
          formData
        );
        // console.log(response, "RESPONSE AFTER SUBMIT");

        if (response.status === 440) {
          alert("Session Expired");
          dispatch(logoutUser());
          window.location.href = ADMIN_LOGIN_BASE_URL;
        } else if (response.status === 429) {
          setShowModal(true);
        } else if (response.status === 400) {
          setIsError(true);
          let errorMessage = await response.message;
          setIsResponse(errorMessage);
        } else if (response.status === 200 || response.status === 201) {
          alert("Student successfully registered.");
          setShowToast(true);

          setUGStudentData({
            id: "",
            course_name: "",
            form_no: "",
            enrollment_no: "",
            session: "",
            admission_date: "",
            roll_number: "",
            rank: "",
            first_name: "",
            middle_name: "",
            last_name: "",
            dob: "",
            category: "",
            category_selected: "",
            gender: "",
            nationality: "",
            religion: "",
            student_mobile: "",
            email: "",
            aiims_email: "",
            identification_mark: "",
            std_bloodgrp: "",
            father_name: "",
            mother_name: "",
            student_image: "",
            sign_image: "",
            admission_letter: "",
            corr_stateName: "",
            corr_districtName: "",
            corr_town: "",
            corr_police_station: "",
            corr_post_office: "",
            corr_street_address: "",
            corr_pin: "",
            corr_landline: "",
            corr_mobile: "",
            corr_email: "",
            perm_stateName: "",
            perm_districtName: "",
            perm_town: "",
            perm_police_station: "",
            perm_post_office: "",
            perm_street_address: "",
            perm_pin: "",
            perm_landline: "",
            perm_mobile: "",
            perm_email: "",
            gardi_fname: "",
            gard_lname: "",
            gard_mobile: "",
            guardian_email: "",
            relation: "",
            gardian_address: "",
            school_name: "",
            school_state: "",
            school_city: "",
            school_board: "",
            pass_year: "",
            school_marks: "",
            marks_obtained: "",
            schoolMarksInPercentage: "",
            school_cgpa: "",
            school_division: "",
            schoolname_ten_plus_two: "",
            state_Name_ten_plus_two: "",
            city_ten_plus_two: "",
            board_ten_plus_two: "",
            passyear_ten_plus_two: "",
            marks_ten_plus_two: "",
            marks_ten_plus_two_obtained: "",
            tenPlusTwoMarksInPercentage: "",
            cgpa_ten_plus_two: "",
            division_ten_plus_two: "",
            MedicalRegistration: "", // not used here
            RegisrationState: "", // not used here
            PGRegistration: "", // not used here
            isDetailsUpdated: "",
            status: "",
            rejectionReason: "",
            same_addr: false,
            updated_by: "",
          });
          // window.location.reload();
          setButtonLoding(false);
        }
      } catch (error) {
        console.error("Error during form submission:", error);
      }
    }
    setValidated(true);
  };

  // const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
  //   setButtonLoding(true);
  //   const form = event.currentTarget;
  //   if (form.checkValidity() === false) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //   } else {
  //     event.preventDefault();
  //     const formData = new FormData();

  //     const { id, last_name } = ugStudentData;

  //     // formData.append("text", text);
  //     // formData.append("content", content);
  //     // formData.append("department", "FOM");
  //     // formData.append("createdBy", profileName);
  //     // if (image) {
  //     //   formData.append("image", image);
  //     // }

  //     // try {
  //     //   const response = await api.createData(
  //     //     `/api/department/${profileId}/${Key_Key}`,
  //     //     formData
  //     //   );

  //     //   if (response.status === 440) {
  //     //     alert("Session Expired");
  //     //     dispatch(logoutUser());
  //     //     window.location.href = ADMIN_LOGIN_BASE_URL;
  //     //   }
  //     //   if (response.status === 429) {
  //     //     // console.log("response---6566656", response)
  //     //     setShowModal(true);
  //     //   }
  //     //   // console.log("response--statue", response)

  //     //   if (response.status === 400) {
  //     //     setIsError(true);
  //     //     let errorMessage = await response.message;
  //     //     setIsResponse(errorMessage);
  //     //   }

  //     //   if (response.status == 200 || response.status == 201) {
  //     //     alert("Added");
  //     //     window.location.reload();
  //     //     setButtonLoding(false);
  //     //   }
  //     // } catch (error) {
  //     //   console.error("Error during edit the banner:", error);
  //     // }
  //   }
  //   setValidated(true);
  // };

  let objectId: any;

  if (data.length > 0) {
    const { _id } = data[0];
    objectId = _id;
  }

  // -----------------------Fetch all state and district data----------------------

  // Fetch all states
  useEffect(() => {
    fetchStates();
  }, []);

  const fetchStates = async () => {
    try {
      const response = await fetch(
        "https://cdn-api.co-vin.in/api/v2/admin/location/states"
      );
      const data = await response.json();
      setStates(data.states);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  // Fetch districts when a state is selected
  useEffect(() => {
    if (selectedState) {
      fetchDistricts(selectedState);
    }
  }, [selectedState]);

  const fetchDistricts = async (stateId: any) => {
    try {
      const response = await fetch(
        `https://cdn-api.co-vin.in/api/v2/admin/location/districts/${stateId}`
      );
      const data = await response.json();
      setDistricts(data.districts);
    } catch (error) {
      console.error("Error fetching districts:", error);
    }
  };

  const handleStateChange = (event: any) => {
    // handle change for state and district change

    const { name, value, files, type, checked } = event.target;
    setSelectedState(value);
    // console.log(states, "All state Data");
    // console.log(value, "State value");

    const selectedStateObject = states.filter(
      (state: any) => state.state_id === value
    )[0];

    // console.log(selectedStateObject, "Selected STATE");

    if (type === "checkbox") {
      // If the input type is a checkbox, update the state with the checked status
      setUGStudentData((prevData) => ({ ...prevData, [name]: checked }));
    } else if (files && files.length > 0) {
      // If the input type is file, update the state with the file and its name
      setUGStudentData((prevData) => ({
        ...prevData,
        [name]: files[0],
        // image: files[0].name,
      }));
    } else if (type === "select-one") {
      // If the input type is select-one (dropdown), update the state with the selected value
      setUGStudentData((prevData) => ({ ...prevData, [name]: value }));
    } else {
      // If the input type is not a file or checkbox, update the state as usual
      setUGStudentData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const isValidZipCode = (zip: any) => /^\d{6}$/.test(zip);

  // console.log(ugStudentData, "DATA SELECTED");

  // function for create year

  const generateYearOptions = (start: any, end: any) => {
    const years = [];
    for (let year = end; year >= start; year--) {
      years.push(
        <option key={year} value={year}>
          {year}
        </option>
      );
    }
    return years;
  };

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          { label: "Registration", path: "/registration/ug-registration" },
          {
            label: "UG Registration",
            path: "/forms/basic",
            active: true,
          },
        ]}
        title={"Player Registration"}
      />
      <Card>
        <h3 className="text"></h3>
        <Toast
          className="d-inline-block m-1"
          bg="success"
          onClose={() => setShowToast(false)} // Handle close event
          show={showToast} // Show toast based on state
          delay={3000} // Auto-hide after 3 seconds
          autohide
        >
          <Toast.Body className="text-white">
            Registration Successful.
          </Toast.Body>
        </Toast>
        <Card.Body>
          <Row>
            {/* {data.length === 0 ? ( */}
            {Role && Role === "Chess" ? (
              <Row>
                <Form
                  style={{ width: "100%" }}
                  onSubmit={handleFormSubmit}
                  encType="multipart/form-data"
                >
                  <Row>
                    <Col lg={6}>
                      <h4 className="header-title mt-0">Student Information</h4>

                      {/* <Toast className="d-inline-block m-1" bg="success">
                        <Toast.Body className={"text-white"}>
                          Registration Successfull.
                        </Toast.Body>
                      </Toast> */}
                      {isError && (
                        <Alert variant="danger" className="my-2">
                          {isResponse}
                        </Alert>
                      )}
                      {showModal && <AuthModal />}

                      <Form.Group className="mb-3 mt-2" onChange={handleChange}>
                        <Form.Label>
                          Course Name
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Select name="course_name" required>
                          <option
                            value=""
                            //  disabled
                          >
                            Select course type
                          </option>
                          <option value="01">MBBS</option>
                          <option value="02">B.Sc. (Hons.) Nursing</option>
                        </Form.Select>
                      </Form.Group>

                      <Form.Group className="mb-3 mt-2" onChange={handleChange}>
                        <Form.Label>
                          Batch/Session
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Select name="session" required>
                          <option
                            value=""
                            //  disabled
                          >
                            Select session
                          </option>

                          <option value="2023">2023</option>
                        </Form.Select>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start font-weight-bold">
                          Roll Number
                          {/* <span style={{ color: "red" }}> *</span> */}
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="roll_number"
                          placeholder="Enter Roll Number"
                          value={ugStudentData?.roll_number}
                          // required
                          onChange={handleChange}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid data.
                        </Form.Control.Feedback>
                      </Form.Group>

                      {/* <Form.Group>
                  <Form.Label className="d-flex  pt-2 justify-content-start font-weight-bold">
                    District <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="district"
                    placeholder="Enter District"
                    value={item?.district}
                    onChange={handleChange}
                  />
                </Form.Group> */}

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          Admission Date
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="date"
                          name="admission_date"
                          placeholder="Enter Admission Date"
                          value={ugStudentData?.admission_date}
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid data.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          All India Rank
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="rank"
                          placeholder="MBBS/Nursing RANK As Per Enterance Examination"
                          value={ugStudentData?.rank}
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid data.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          Name
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="first_name"
                          placeholder="First Name as per SSC certificate or GAZETTE Notification"
                          value={ugStudentData?.first_name}
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid Name.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          Date of Birth
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="date"
                          name="dob"
                          value={ugStudentData?.dob}
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid data.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="mb-3 mt-2" onChange={handleChange}>
                        <Form.Label>
                          Original Category
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Select name="category" required>
                          <option
                            value=""
                            //  disabled
                          >
                            Select Category
                          </option>
                          <option value="GEN">GEN</option>
                          <option value="GENPWBD">GEN PwBD</option>
                          <option value="OBC">OBC</option>
                          <option value="OBCPWBD">OBC PwBD</option>
                          <option value="ST">ST</option>
                          <option value="STPWBD">ST PwBD</option>
                          <option value="SC">SC</option>
                          <option value="SCPWBD">SC PwBD</option>
                          <option value="EWS">EWS</option>
                          <option value="EWSPWBD">EWS PwBD</option>
                        </Form.Select>
                      </Form.Group>

                      <Form.Group className="mb-3 mt-2" onChange={handleChange}>
                        <Form.Label>
                          Category Selected
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Select name="category_selected" required>
                          <option
                            value=""
                            // disabled
                          >
                            Select Category
                          </option>
                          <option value="GEN">GEN</option>
                          <option value="GENPWBD">GEN PwBD</option>
                          <option value="OBC">OBC</option>
                          <option value="OBCPWBD">OBC PwBD</option>
                          <option value="ST">ST</option>
                          <option value="STPWBD">ST PwBD</option>
                          <option value="SC">SC</option>
                          <option value="SCPWBD">SC PwBD</option>
                          <option value="EWS">EWS</option>
                          <option value="EWSPWBD">EWS PwBD</option>
                        </Form.Select>
                      </Form.Group>

                      <Form.Group className="mb-3 mt-2" onChange={handleChange}>
                        <Form.Label>
                          Gender
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Select name="gender" required>
                          <option
                            value=""
                            //  disabled
                          >
                            Select
                          </option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Other">Other</option>
                        </Form.Select>
                      </Form.Group>

                      <Form.Group className="mb-3 mt-2" onChange={handleChange}>
                        <Form.Label>
                          Nationality
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Select name="nationality" required>
                          <option
                            value=""
                            // disabled
                          >
                            Select
                          </option>
                          <option value="Indian">Indian</option>
                          <option value="Other">Other</option>
                        </Form.Select>
                      </Form.Group>

                      <Form.Group className="mb-3 mt-2" onChange={handleChange}>
                        <Form.Label>
                          Religion
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Select name="religion" required>
                          <option
                            value=""
                            // disabled
                          >
                            Select
                          </option>
                          <option value="Hinduism">Hinduism</option>
                          <option value="Islam">Islam</option>
                          <option value="Christianity">Christianity</option>
                          <option value="Sikhism">Sikhism</option>
                          <option value="Buddhism">Buddhism</option>
                          <option value="Jainism">Jainism</option>
                          <option value="Zoroastriansim">Zoroastriansim</option>
                          <option value="Judaism">Judaism</option>
                          <option value="Others">Others</option>
                        </Form.Select>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          Mobile
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="student_mobile"
                          placeholder="Enter Mobile Number"
                          value={ugStudentData?.student_mobile}
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid data.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          Email Address
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder="Enter Email Address"
                          value={ugStudentData?.email}
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid data.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          Email Address (Provided by AIIMS Patna)
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="email"
                          name="aiims_email"
                          placeholder="Enter Email Address"
                          value={ugStudentData?.aiims_email}
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid data.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          Identification Mark
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="identification_mark"
                          placeholder="Enter Identification Mark"
                          value={ugStudentData?.identification_mark}
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid data.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="mb-3 mt-2" onChange={handleChange}>
                        <Form.Label>
                          Blood Group
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Select name="std_bloodgrp" required>
                          <option
                            value=""
                            // disabled
                          >
                            Blood Group
                          </option>
                          <option value="A+">A+</option>
                          <option value="A-">A-</option>
                          <option value="B+">B+</option>
                          <option value="B-">B-</option>
                          <option value="O+">O+</option>
                          <option value="O-">O-</option>
                          <option value="AB+">AB+</option>
                          <option value="AB-">AB-</option>
                        </Form.Select>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          Father's Name
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="father_name"
                          placeholder="Enter Family Name"
                          value={ugStudentData?.father_name}
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid data.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          Mother's Name
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="mother_name"
                          placeholder="Enter Family Name"
                          value={ugStudentData?.mother_name}
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid data.
                        </Form.Control.Feedback>
                      </Form.Group>

                      {/* <Form.Group className="d-flex pt-2 justify-content-start">
                    <Form.Check
                      type="checkbox"
                      id="currentlyWorkingCheckbox"
                      label="I currently work here"
                      name="currentlyWorking"
                      checked={item?.currentlyWorking}
                      onChange={handleChange}
                    />
                  </Form.Group> */}
                    </Col>

                    <Col lg={6}>
                      <h4 className="header-title mt-1">
                        Contact Details{" "}
                        <span className="header-title mt-2 text-primary">
                          (Correspondence Address)
                        </span>
                      </h4>

                      {/* <h5 className="header-title mt-2 text-primary">
                        Correspondence Address:
                      </h5> */}

                      <Form.Group className="mb-3 mt-2">
                        <Form.Label>
                          State <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Select
                          name="corr_stateName"
                          onChange={handleStateChange}
                          required
                        >
                          <option
                            value=""
                            //  disabled
                          >
                            Select
                          </option>
                          {states?.map((state: any) => (
                            <option
                              key={state?.state_id}
                              value={state?.state_id}
                            >
                              {state?.state_name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>

                      <Form.Group className="mb-3 mt-2">
                        <Form.Label>
                          District <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Select
                          name="corr_districtName"
                          onChange={handleChange}
                          required
                        >
                          <option
                            value=""
                            //  disabled
                          >
                            Select
                          </option>
                          {districts?.map((district: any) => (
                            <option
                              key={district?.district_id}
                              value={district?.district_name}
                            >
                              {district?.district_name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>

                      {/* Code of 14/03/2024 */}

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          Town/Village
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="corr_town"
                          placeholder="Enter Town/Village Name"
                          value={ugStudentData?.corr_town}
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid data.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          Police Station
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="corr_police_station"
                          placeholder="Enter Police Station"
                          value={ugStudentData?.corr_police_station}
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid data.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          Post Office
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="corr_post_office"
                          placeholder="Enter Post Office"
                          value={ugStudentData?.corr_post_office}
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid data.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          Street Address
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          name="corr_street_address"
                          placeholder="Enter Street Address"
                          value={ugStudentData?.corr_street_address}
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid data.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          Pin-Code
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="corr_pin"
                          placeholder="Enter Pin-code Number"
                          value={ugStudentData?.corr_pin}
                          onChange={handleChange}
                          required
                          isInvalid={!isValidZipCode(ugStudentData?.corr_pin)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid 6-digit zip code.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          Land Line
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="corr_landline"
                          placeholder="Enter Land Line"
                          value={ugStudentData?.corr_landline}
                          onChange={handleChange}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid data.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          Mobile (Parent/Guardian)
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="corr_mobile"
                          placeholder="Enter Mobile Number"
                          value={ugStudentData?.corr_mobile}
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid data.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          Email (Parent/Guardian)
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="corr_email"
                          placeholder="Enter Email (Parent/Guardian)"
                          value={ugStudentData?.corr_email}
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid data.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <h5 className="header-title mt-2 text-primary">
                        Permanent Address:
                      </h5>

                      <Form.Group className="d-flex pt-2 justify-content-start">
                        <Form.Check
                          type="checkbox"
                          id="same_addr"
                          label="Same as above"
                          name="same_addr"
                          checked={ugStudentData?.same_addr}
                          onChange={handleChange}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3 mt-2">
                        <Form.Label>
                          State <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Select
                          name="perm_stateName"
                          onChange={handleStateChange}
                          required
                          value={
                            ugStudentData?.same_addr
                              ? ugStudentData?.corr_stateName
                              : ugStudentData?.perm_stateName
                          }
                        >
                          <option
                            value=""
                            // disabled
                          >
                            Select
                          </option>
                          {states?.map((state: any) => (
                            <option
                              key={state?.state_id}
                              value={state?.state_id}
                            >
                              {state?.state_name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>

                      <Form.Group className="mb-3 mt-2">
                        <Form.Label>
                          District <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Select
                          name="perm_districtName"
                          onChange={handleChange}
                          required
                          value={
                            ugStudentData?.same_addr
                              ? ugStudentData?.corr_districtName
                              : ugStudentData?.perm_districtName
                          }
                        >
                          <option
                            value=""
                            // disabled
                          >
                            Select
                          </option>
                          {districts?.map((district: any) => (
                            <option
                              key={district?.district_id}
                              value={district?.district_name}
                            >
                              {district?.district_name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          Town/Village
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="perm_town"
                          placeholder="Enter Town/Village Name"
                          value={
                            ugStudentData?.same_addr
                              ? ugStudentData?.corr_town
                              : ugStudentData?.perm_town
                          }
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid data.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          Police Station
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="perm_police_station"
                          placeholder="Enter Police Station"
                          value={
                            ugStudentData?.same_addr
                              ? ugStudentData?.corr_police_station
                              : ugStudentData?.perm_police_station
                          }
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid data.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          Post Office
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="perm_post_office"
                          placeholder="Enter Post Office"
                          value={
                            ugStudentData?.same_addr
                              ? ugStudentData?.corr_post_office
                              : ugStudentData?.perm_post_office
                          }
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid data.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          Street Address
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          name="perm_street_address"
                          placeholder="Enter Street Address"
                          value={
                            ugStudentData?.same_addr
                              ? ugStudentData?.corr_street_address
                              : ugStudentData?.perm_street_address
                          }
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid data.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          Pin-Code
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="perm_pin"
                          placeholder="Enter Pin-code Number"
                          value={
                            ugStudentData?.same_addr
                              ? ugStudentData?.corr_pin
                              : ugStudentData?.perm_pin
                          }
                          onChange={handleChange}
                          required
                          isInvalid={
                            !isValidZipCode(
                              ugStudentData?.same_addr
                                ? ugStudentData?.corr_pin
                                : ugStudentData?.perm_pin
                            )
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid 6-digit zip code.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          Land Line
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="perm_landline"
                          placeholder="Enter Land Line"
                          value={
                            ugStudentData?.same_addr
                              ? ugStudentData?.corr_landline
                              : ugStudentData?.perm_landline
                          }
                          onChange={handleChange}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid data.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          Mobile (Parent/Guardian)
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="perm_mobile"
                          placeholder="Enter Mobile Number"
                          value={
                            ugStudentData?.same_addr
                              ? ugStudentData?.corr_mobile
                              : ugStudentData?.perm_mobile
                          }
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid data.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          Email (Parent/Guardian)
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="perm_email"
                          placeholder="Enter Email (Parent/Guardian)"
                          value={
                            ugStudentData?.same_addr
                              ? ugStudentData?.corr_email
                              : ugStudentData?.perm_email
                          }
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid data.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  {/* Code of 15/03/2024 */}

                  <h5 className="header-title mt-2 text-primary">
                    Emergency/Local Guardian Contact:
                  </h5>

                  <Form.Group>
                    <Form.Label className="d-flex pt-2 justify-content-start">
                      Guardian First Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="gardi_fname"
                      placeholder="Enter Guardian First Name"
                      value={ugStudentData?.gardi_fname}
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid data.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="d-flex pt-2 justify-content-start">
                      Guardian Last Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="gard_lname"
                      placeholder="Enter Guardian Last Name"
                      value={ugStudentData?.gard_lname}
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid data.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="d-flex pt-2 justify-content-start">
                      Guardian Mobile No
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="gard_mobile"
                      placeholder="Enter Guardian Mobile No"
                      value={ugStudentData?.gard_mobile}
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid data.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="d-flex pt-2 justify-content-start">
                      Guardian Email Address
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="guardian_email"
                      placeholder="Enter Guardian email address"
                      value={ugStudentData?.guardian_email}
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid data.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="d-flex pt-2 justify-content-start">
                      Relationship Type
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="relation"
                      placeholder="Enter Relationship Type"
                      value={ugStudentData?.relation}
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid data.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="d-flex pt-2 justify-content-start">
                      Guardian Address
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="gardian_address"
                      placeholder="Enter Guardian Address"
                      value={ugStudentData?.gardian_address}
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid data.
                    </Form.Control.Feedback>
                  </Form.Group>

                  {/* <Row>
                    <Col lg={6}>
                      <h4 className="header-title mt-2">
                        Educational Qualification{" "}
                        <span className="header-title mt-2 text-primary">
                          (Matriculation:)
                        </span>
                      </h4>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          School Name
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="school_name"
                          placeholder="Enter School Name"
                          value={ugStudentData?.school_name}
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid data.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="mb-3 mt-2">
                        <Form.Label>
                          State <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Select
                          name="school_state"
                          onChange={handleStateChange}
                          required
                        >
                          <option value="">Select</option>
                          {states?.map((state: any) => (
                            <option
                              key={state?.state_id}
                              value={state?.state_id}
                            >
                              {state?.state_name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          City
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="school_city"
                          placeholder="Enter City"
                          value={ugStudentData?.school_city}
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid data.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          Board
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="school_board"
                          placeholder="Enter Board"
                          value={ugStudentData?.school_board}
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid data.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          Passing Year<span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="pass_year"
                          value={ugStudentData?.pass_year}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Select</option>
                          {generateYearOptions(
                            1900,
                            new Date().getFullYear()
                          )}{" "}
                        </Form.Control>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          Total Marks
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="school_marks"
                          placeholder="Total Marks"
                          value={ugStudentData?.school_marks}
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid data.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          Marks Obtained
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="marks_obtained"
                          placeholder="Marks Obtained"
                          value={ugStudentData?.marks_obtained}
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid data.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          % of Marks
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="schoolMarksInPercentage"
                          placeholder="% of Marks"
                          value={ugStudentData?.schoolMarksInPercentage}
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid data.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <p className="text-primary mt-2 mb-0">OR</p>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          CGPA
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="school_cgpa"
                          placeholder="CGPA"
                          value={ugStudentData?.school_cgpa}
                          onChange={handleChange}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid data.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="mb-3 mt-2" onChange={handleChange}>
                        <Form.Label>
                          Division
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Select name="school_division" required>
                          <option value="">Select</option>
                          <option value="1st">1st</option>
                          <option value="2nd">2nd</option>
                          <option value="3rd">3rd</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    <Col lg={6}>
                      <h4 className="header-title mt-2 text-primary">
                        Intermediate (10+2):
                      </h4>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          College/School Name
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="schoolname_ten_plus_two"
                          placeholder="Enter College/School Name"
                          value={ugStudentData?.schoolname_ten_plus_two}
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid data.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="mb-3 mt-2">
                        <Form.Label>
                          State <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Select
                          name="state_Name_ten_plus_two"
                          onChange={handleStateChange}
                          required
                          value={ugStudentData?.state_Name_ten_plus_two}
                        >
                          <option value="">Select</option>
                          {states?.map((state: any) => (
                            <option
                              key={state?.state_id}
                              value={state?.state_id}
                            >
                              {state?.state_name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          City
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="city_ten_plus_two"
                          placeholder="Enter City"
                          value={ugStudentData?.city_ten_plus_two}
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid data.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          Board
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="board_ten_plus_two"
                          placeholder="Enter Board"
                          value={ugStudentData?.board_ten_plus_two}
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid data.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          Passing Year<span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="passyear_ten_plus_two"
                          value={ugStudentData?.passyear_ten_plus_two}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Select</option>
                          {generateYearOptions(
                            1900,
                            new Date().getFullYear()
                          )}{" "}
                        </Form.Control>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          Total Marks
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="marks_ten_plus_two"
                          placeholder="Total Marks"
                          value={ugStudentData?.marks_ten_plus_two}
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid data.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          Marks Obtained
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="marks_ten_plus_two_obtained"
                          placeholder="Marks Obtained"
                          value={ugStudentData?.marks_ten_plus_two_obtained}
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid data.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          % of Marks
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="tenPlusTwoMarksInPercentage"
                          placeholder="% of Marks"
                          value={ugStudentData?.tenPlusTwoMarksInPercentage}
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid data.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <p className="text-primary mt-2 mb-0">OR</p>

                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          CGPA
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="cgpa_ten_plus_two"
                          placeholder="CGPA"
                          value={ugStudentData?.cgpa_ten_plus_two}
                          onChange={handleChange}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid data.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="mb-3 mt-2" onChange={handleChange}>
                        <Form.Label>
                          Division
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Select name="division_ten_plus_two" required>
                          <option value="">Select</option>
                          <option value="1st">1st</option>
                          <option value="2nd">2nd</option>
                          <option value="3rd">3rd</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row> */}

                  <Form.Group className="pt-2 pb-2">
                    <Button type="submit" disabled={buttonLoading}>
                      {buttonLoading ? "Submitting" : "Submit"}
                    </Button>
                  </Form.Group>
                </Form>
              </Row>
            ) : (
              ""
            )}
          </Row>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default UgRegistration;
